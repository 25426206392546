// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-oauth-github-js": () => import("./../src/pages/oauth/github.js" /* webpackChunkName: "component---src-pages-oauth-github-js" */),
  "component---src-pages-signin-js": () => import("./../src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-statistics-tsx": () => import("./../src/pages/statistics.tsx" /* webpackChunkName: "component---src-pages-statistics-tsx" */)
}

