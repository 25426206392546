module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-144586274-2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pulpo - Personal github statistics","short_name":"Pulpo - Personal github statistics","start_url":"/","icon":"src/images/pulpo.gif"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
